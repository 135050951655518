import * as React from 'react';
import {withPrefix} from 'gatsby';
import './src/sass/global.scss';
import 'react-datepicker/dist/react-datepicker.css';

export const onRouteUpdate = ({location}) => {
  /**
   * アンカー付き遷移でスライダーがあるときの位置のズレを調整
   * （iPhoneで現象あり）
   */
  setTimeout(function () {
    if (location.hash) {
      const targetElement = document.getElementById(
        location.hash.replace('#', '')
      );

      const offset = window.pageYOffset;
      const rect = targetElement
        ? targetElement.getBoundingClientRect().top
        : -offset;

      //htmlタグのcss情報を取得。scrool-padding-top必須
      const html_element = document.getElementsByTagName('html');
      const scrool_padding_top = getComputedStyle(
        html_element[0]
      ).scrollPaddingTop;
      const gap = parseInt(scrool_padding_top);

      const target = rect + offset - gap;
      window.scrollTo({
        top: target,
      });
    }
  }, 500);
};
